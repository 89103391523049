<yat>
    <a class="yat" href={ state.address }>
        <raw html={ image } />
    </a>
    <style>
        .yat {
            /* Might need to be done differently to be browser-compatible */
            text-decoration: none;
            border-radius: 99em;
            padding: 5px 0px 1px 8px; /* top right bottom left */
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

            /* Casey's Broken Camera https://www.eggradients.com/category/flame-gradient */
            background-color: #a40606;
            background-image: linear-gradient(315deg, #a40606 0%, #d98324 74%);
        }
    </style>

    <script>
    import Raw from '../includes/raw.riot'

    let unicode = '🎩🎸💻⚙️';
    let address = `https://y.at/${unicode}`;
    
    export default {
        onBeforeMount() {
            joypixels.imageType = 'png';
            joypixels.sprites = true;
            this.image = joypixels.toImage(unicode);
            // https://github.com/joypixels/emoji-toolkit/blob/master/USAGE.md
        },
        components: {
            Raw
        }
    }
    </script>
</yat>
